import cx from 'classnames';
import { FastField } from 'formik';
import BackgroundImage from 'gatsby-background-image';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getDiscountByPerson } from "../forms/main-form2";
import { getDiscountPrice } from '../forms/main-form2';

import styles from './price.module.css';

// function getDiscountPrice(price, plan) {
//   const discount =
//     plan && plan === "2 měsíce" ? 5 : plan && plan === "3 měsíce" ? 8 : null
//   return (price - price * (discount / 100)).toFixed()
// }
//
// function getDiscountPricePerPortion(price, plan) {
//   const discountPortion = plan && plan === "2 týdny" ? 5 : plan
//   return ((price - price * (discountPortion / 100)).toFixed() / 5).toFixed()
// }


// function getDiscountPrice(price) {
//   const discount = 10
//  // TODO: - Когда от 1 числа мы поменяем скидку на 20%, то при заказе для 1,2 людей скидка будет по-прежнему 20%, только в случае когда клиент захочет сделать заказ для троих и более людей, тогда скидка будет 30%.
//   return (price - price * (discount / 100)).toFixed(2)
// }

function getDiscountPricePerPortion(price) {
  const discountPortion = 10
  return ((price - price * (discountPortion / 100)).toFixed() / 5).toFixed(2)
}

export const Price = ({ price = 0, oldPrice, plan = null, isNew = false, person = 1  }) => {
  const [discount, setDiscount] = useState(0)
  const [discountPrice, setDiscountPrice] = useState(null)
  const [discountPortionPrice, setDiscountPortionPrice] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    const discountPriceValue = getDiscountPrice(oldPrice, person)
    setDiscountPrice(discountPriceValue)
    const discountPortionPriceValue = getDiscountPricePerPortion(price)
    setDiscountPortionPrice(discountPortionPriceValue)
  }, [price, oldPrice])

  useEffect(() => {
    console.log("--- discountPrice", discountPrice);
  }, [discountPrice]);

  useEffect(() => {
    console.log("--- discount in price", person);
    setDiscount(getDiscountByPerson(person));
  }, [person])

  return (
    <div
      className={styles.price}
      style={{
        backgroundColor: isNew ? "#F8F8F8" : "",
        borderRadius: isNew ? "20px" : "",
      }}
    >
      <div className={styles.priceCol}>
        <h5 className={styles.priceTitle}>{t("forms.priceTitlePart1")}</h5>
        <p className={styles.priceText}>{t("forms.priceTitlePart2")}</p>
      </div>
      <div className={cx(styles.priceCol, styles.priceColRight)}>
        <div style={{ textDecoration: "line-through", display: discount ? "block" : "none" }}>
          {/*{oldPrice && `${oldPrice}`} {oldPrice && <span>&euro;</span>}*/}
          <span id="price">{price ? oldPrice : "420"}</span> &euro;
        </div>
        <div
          className={cx(styles.priceValue, { [styles.priceValueNew]: isNew })}
          style={{ color: isNew ? "#3DC383" : "" }}
        >
          {/*<span id="price">{price ? price : "420"}</span> &euro;*/}
          {discountPrice && <span>{discountPrice}</span>} &euro;
        </div>
        {/*{plan === "2 týdny" && (*/}
        {/*  <div className={styles.priceDiscountValue}>*/}
        {/*    <span id="discountPrice">*/}
        {/*      {discountPrice ? discountPrice : "400"}*/}
        {/*    </span>{" "}*/}
        {/*    &euro;*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>

        {discount === 0 ? '' : <div className={cx(styles.priceCol, styles.discountCol)}>
          {t("forms.discountTitle")} <span>{discount}%</span>
        </div>}
        
    </div>
  )
}
